<template>
  <el-dialog
    title="编辑商品"
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents">
      <div class="infodata">
        <div>货号：{{ formData.sku_code }}</div>
        <div>供应商：{{ formData?.supplier?.title }}</div>
      </div>
      <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="商品名称" prop="title">
          <el-input
            v-model="formData.title"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="商品品类" prop="category_tag_id">
          <el-select
            v-model="formData.cateName"
            filterable
            remote
            placeholder="请选择商品品类"
            :remote-method="remoteMethod"
            @visible-change="(val) => onChangeVisible(val)"
            :loading="loading"
            @change="change"
            clearable
            ref="selectRef"
            :popper-class="'custom-select-dropdown'"
            :popper-append-to-body="false"
            :disabled="formData.is_sales_cycle == 1"
          >
            <div>
              <el-option
                v-for="item in list1"
                :key="item.tag_id"
                :label="`${item.p_name}-${item.c_name}-${item.tag_name}`"
                :value="`${item.p_name}-${item.c_name}-${item.tag_name}`"
              >
              </el-option>
            </div>
            <div style="width: 100%; text-align: center" v-if="showNext">
              {{ textwarn }}
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌" prop="brand_name">
          <el-input
            v-model="formData.brand_name"
            :disabled="formData.is_sales_cycle == 1"
            placeholder="请输入品牌"
          ></el-input>
        </el-form-item>
        <el-form-item label="包装方式" prop="package_type">
          <el-select
            v-model="formData.package_type"
            placeholder="请选择包装方式"
            @change="changePackage"
            :disabled="formData.is_sales_cycle == 1"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in packageTypeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- :disabled="
              formData.buyer_id == 0
                ? false
                : formData.cid_is_audit == 1 ||
                  formData.buy_way == 1 ||
                  formData.is_sales_cycle == 1
            " -->
        <el-form-item label="业务员" prop="buyer_id">
          <el-select
            v-model="formData.buyer_id"
            
            placeholder="请选择业务员"
            filterable
          >
            <el-option
              :label="item.fullname"
              :value="item.id"
              v-for="(item, index) in buyer_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规格" prop="spec" v-if="cid_type == 1">
          <el-input
            v-model="formData.spec"
            maxlength="20"
            :disabled="formData.is_sales_cycle == 1"
            show-word-limit
            placeholder="请输入规格"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="规格"
          prop="spec"
          v-if="cid_type == 2 && formData.package_type"
        >
          <div style="display: flex; align-items: center; margin-bottom: 10px">
            <div style="width: 80px; margin-right: 20px; text-align: center">
              每{{ formData.package_type_text }}
            </div>
            <div style="width: 30%; margin-right: 10px">
              <el-input
                v-model="formData.spec_l1_num"
                :disabled="formData.is_sales_cycle == 1"
                placeholder="请输入规格"
              ></el-input>
            </div>
            <div style="width: 15%">
              <el-select
                v-model="formData.spec_l1_unit"
                placeholder="请选择"
                @change="(val) => changeSpec(val, 1)"
                :disabled="formData.is_sales_cycle == 1"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in specsList"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div
            style="display: flex; align-items: center"
            v-if="formData.spec_l1_unit"
          >
            <div style="width: 80px; margin-right: 20px; text-align: center">
              每{{ formData.spec_l1_unit_text }}
            </div>
            <div style="width: 30%; margin-right: 10px">
              <el-input
                v-model="formData.spec_l2_num"
                :disabled="formData.is_sales_cycle == 1"
                show-word-limit
                placeholder="请输入规格"
              ></el-input>
            </div>
            <div style="width: 15%">
              <el-select
                v-model="formData.spec_l2_unit"
                placeholder="请选择"
                :disabled="formData.is_sales_cycle == 1"
                @change="(val) => changeSpec(val, 2)"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in specsSubList"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="产地" prop="origin_id">
          <el-select
            v-model="formData.origin_id"
            placeholder="请选择产地"
            filterable
            clearable
            @change="changeOrigin"
            :disabled="formData.is_sales_cycle == 1"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in originList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="等级" prop="grade">
          <el-select
            v-model="formData.grade"
            placeholder="请选择等级"
            :disabled="formData.is_sales_cycle == 1"
          >
            <el-option
              :label="item"
              :value="item"
              v-for="(item, index) in gradeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="标签"
          prop="promotion_tag_id"
          v-if="
            type == 1 &&
            formData.creator_type != 1 &&
            formData.creator_type != 2
          "
        >
          <el-select
            v-model="promotion_tag_id"
            placeholder="请选择标签"
            multiple
          >
            <!-- @change="promotion_tag_id_change" -->
            <el-option
              @click.native="promotion_tag_id_change($event, item)"
              :label="item.name"
              :value="item.id + ''"
              v-for="(item, index) in promotion_tag_list"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-alert
        title="请确保业务知晓更改事项，后台数据更改均会记录日志"
        type="warning"
        :closable="false"
        show-icon
      >
      </el-alert>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit" :loading="loadingSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  postProductCategoryList,
  postProductUnitList,
  postProductAreaList,
} from "@/api/general/goods.js";

export default {
  name: "Salegoods-edit-dialog",
  props: {
    type: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      dialogVisible: false,
      loadingSubmit: false,
      formData: {
        sku_code: "", //"SR0669956",
        title: "", //"补炖品111",
        cateName: "",
        first_cid: "", //"1",一级分类id
        cid: "", //"20",二级分类id
        category_tag_id: "", //"20",三级分类id
        brand_name: "", //品牌
        package_type: "", // 包装方式
        package_type_text: "",
        buyer_id: "", //"18",业务id
        spec: "", //"dadadada",规格
        spec_l1_num: "",
        spec_l1_unit: "",
        spec_l1_unit_text: "",
        spec_l2_num: "",
        spec_l2_unit: "",
        spec_l2_unit_text: "",
        origin_id: "",
        origin_name: "",
        grade: "",
        promotion_tag_id: "", //标签
      },
      formDatacopy: {
        sku_code: "", //"SR0669956",
        title: "", //"补炖品111",
        cateName: "",
        first_cid: "", //"1",一级分类id
        cid: "", //"20",二级分类id
        category_tag_id: "", //"20",三级分类id
        brand_name: "", //品牌
        package_type: "", // 包装方式
        package_type_text: "",
        buyer_id: "", //"18",业务id
        spec: "", //"dadadada",规格
        spec_l1_num: "",
        spec_l1_unit: "",
        spec_l1_unit_text: "",
        spec_l2_num: "",
        spec_l2_unit: "",
        spec_l2_unit_text: "",
        origin_id: "",
        origin_name: "",
        grade: "",
        promotion_tag_id: "", //标签
      },
      promotion_tag_id: [],
      datatips: [
        {
          name: "商品名称",
          field: "title",
        },
        // {
        //   name: "一级分类",
        //   field: "first_cid",
        // },
        // {
        //   name: "二级分类",
        //   field: "cid",
        // },
        {
          name: "商品分类",
          field: "category_tag_id",
        },
        {
          name: "品牌",
          field: "brand_name",
        },
        {
          name: "包装方式",
          field: "package_type",
        },
        {
          name: "业务",
          field: "buyer_id",
        },
        {
          name: "规格",
          field: "spec",
        },
        {
          name: "一级规格数量",
          field: "spec_l1_num",
        },
        {
          name: "一级子包装单位",
          field: "spec_l1_unit",
        },
        {
          name: "二级规格数量",
          field: "spec_l2_num",
        },
        {
          name: "二级子包装单位",
          field: "spec_l2_unit",
        },
        {
          name: "产地",
          field: "origin_id",
        },
        {
          name: "等级",
          field: "grade",
        },
        {
          name: "标签",
          field: "promotion_tag_id",
        },
      ],
      value: [],
      options: [],
      list1: [],
      showNext: false,
      textwarn: "努力加载中...",
      // id: "",
      dataLock: false,
      name: "",
      page: 1,
      pageSize: 20,
      loading: false,
      category_tag_list: [
        // {
        //   id: 0,
        //   name: "无",
        // },
      ],
      buyer_list: [],
      promotion_tag_list: [
        {
          id: 0,
          name: "无",
        },
      ],
      // props: {
      //   value: "id", //	指定选项的值为选项对象的某个属性值	string	—	'value'
      //   label: "name", //	指定选项标签为选项对象的某个属性值	string	—	'label'
      //   children: "children", //	指定选项的子选项为选项对象的某个属性值
      //   disabled: "cid_is_audit",
      // },
      rules: {
        title: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        first_cid: [
          { required: true, message: "请选商品品类", trigger: "change" },
        ],
        category_tag_id: [
          { required: true, message: "请选商品品类", trigger: "change" },
        ],
        package_type: [
          { required: true, message: "请选择包装方式", trigger: "change" },
        ],
        buyer_id: [
          { required: true, message: "请选业务员", trigger: "change" },
        ],
        grade: [{ required: true, message: "请选择等级", trigger: "change" }],
        promotion_tag_id: [
          { required: true, message: "请选标签", trigger: "change" },
        ],
      },
      originList: [], // 产地列表
      gradeList: [], // 等级列表
      packageTypeList: [], //包装方式
      specsList: [], // 一级规格
      specsSubList: [], // 二级规格
      cid_type: "", // 1为果蔬 2为非果蔬
    };
  },
  methods: {
    // 商品枚举规格单位
    async postAjaxProductUnitList() {
      try {
        const res = await postProductUnitList();
        console.log(res, "postProductUnitList==");
        this.gradeList = res.data.grade || [];
        this.packageTypeList = res.data.package_type || [];
        this.specsList = res.data.specs || [];
        this.specsSubList = res.data.specs_sub || [];
      } catch (error) {
        console.log(error, "postProductUnitList");
      }
    },
    // 产地列表
    async postAjaxProductAreaList(e) {
      try {
        const res = await postProductAreaList({
          page: 1,
          pageSize: 9999,
          first_cid: e,
        });
        this.originList = res.data.data || [];
        console.log(res, "postProductAreaList==");
      } catch (error) {
        console.log(error, "postProductAreaList");
      }
    },
    /**
     * 下拉框滚动加载下一页数据
     */
    loadMoreOptions() {
      console.log(this.showNext, "111");
      if (this.showNext) {
        console.log("还没请求完呢");
        return;
      }
      this.showNext = true;
      this.page++;
      this.getOptionsList();
    },
    /**
     * 监听下拉框滚动事件
     */
    addScrollEvent() {
      const dropdownMenu = this.$refs.selectRef.$refs.popper.$el.querySelector(
        ".el-select-dropdown__wrap"
      );
      console.log(dropdownMenu, "添加滚动事件");
      dropdownMenu.addEventListener("scroll", this.handleScroll);
    },
    /**
     * 移除下拉监听的滚动事件
     */
    removeScrollEvent() {
      const dropdownMenu = this.$refs.selectRef.$el.querySelector(
        ".el-select-dropdown__wrap"
      );
      console.log(dropdownMenu, "移除滚动事件");
      dropdownMenu.removeEventListener("scroll", this.handleScroll);
    },
    /**
     * 下拉框滚动到底部 加载下一页数据
     */
    handleScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        console.log(scrollTop, scrollHeight, clientHeight, "到底部啦====");
        this.loadMoreOptions();
      }
    },
    /**
     * 下拉框出现/隐藏时触发
     */
    onChangeVisible(e, index) {
      if (e) {
        this.list1 = [];
        this.name = "";
        this.page = 1;
        this.dataLock = false;
        this.getOptionsList();
        this.$nextTick(() => {
          this.addScrollEvent();
        });
      } else {
        this.removeScrollEvent();
        this.list1 = [];
        this.name = "";
        this.dataLock = false;
        this.page = 1;
      }
      // 每次 下拉框出现/隐藏  滚动到顶部
      const dropdownMenu = this.$refs.selectRef.$el.querySelector(
        ".el-select-dropdown__wrap"
      );
      dropdownMenu.scrollTop = 0;
    },
    // 包装方式下拉
    changePackage(e) {
      console.log(e, "e===");
      this.packageTypeList.map((pItem) => {
        if (pItem.id == e) {
          this.formData.package_type_text = pItem.name;
        }
      });
    },
    // 一级规格 二级规格下啦
    changeSpec(e, type) {
      if (type == 1) {
        this.specsList.map((sItem) => {
          if (sItem.id == e) {
            this.formData.spec_l1_unit_text = sItem.name;
          }
        });
      } else {
        this.specsSubList.map((sItem2) => {
          if (sItem2.id == e) {
            this.formData.spec_l2_unit_text = sItem2.name;
          }
        });
      }
    },
    // 修改产地下拉
    changeOrigin(e) {
      this.originList.map((oItem) => {
        if (oItem.id == e) {
          this.formData.origin_name = oItem.name;
        }
      });
    },
    /**
     * 商品品类下拉框改变
     */
    change(e) {
      console.log(e, this.list1, this.formData.category_tag_id, "e==");
      let tmpArr = e.split("-");
      let tmpObj = {};
      this.list1.map((obj) => {
        if (
          obj.p_name == tmpArr[0] &&
          obj.c_name == tmpArr[1] &&
          obj.tag_name == tmpArr[2]
        ) {
          tmpObj = obj;
        }
      });
      this.formData.first_cid = tmpObj.pid;
      this.formData.cid = tmpObj.c_id;
      this.formData.category_tag_id = tmpObj.tag_id;
      this.cid_type = tmpObj.c_type;
      // 联动产地 规格
      this.postAjaxProductAreaList(tmpObj.pid);
      this.formData.spec = "";
      this.formData.spec_l1_num = "";
      this.formData.spec_l1_unit = "";
      this.formData.spec_l1_unit_text = "";
      this.formData.spec_l2_num = "";
      this.formData.spec_l2_unit = "";
      this.formData.spec_l2_unit_text = "";
      this.formData.origin_id = "";
      this.formData.origin_name = "";
      console.log(tmpObj, this.formData, tmpArr, "333");
    },
    /**
     *下拉框远程搜索
     */
    remoteMethod(query) {
      if (query !== "") {
        console.log(query, "重新请求000");
        this.tool.debounce(async () => {
          // 重新请求数据时 该下拉框滚动到顶部
          const dropdownMenu = this.$refs.selectRef.$el.querySelector(
            ".el-select-dropdown__wrap"
          );
          dropdownMenu.scrollTop = 0;
          // 重置数据
          this.loading = true;
          this.name = query;
          this.page = 1;
          this.list1 = [];
          this.dataLock = false;
          this.getOptionsList(query);
        });
      } else {
        this.list1 = [];
        this.name = "";
        this.page = 1;
        this.dataLock = false;
        // this.getOptionsList();
      }
    },
    /**
     *获取品类列表
     */
    getOptionsList: async function (e) {
      if (!this.dataLock) {
        try {
          const res = await postProductCategoryList({
            page: this.page,
            key_words: e ? e : this.name,
            pageSize: 9999,
            sku_code: this.formData.sku_code,
          });
          if (res.data.list.length >= 20) {
            this.list1 = this.list1.concat(res.data.list);
            this.textwarn = "努力加载中...";
            this.dataLock = false;
          } else if (res.data.list.length < 20) {
            this.list1 = this.list1.concat(res.data.list);
            this.textwarn = "到底了";
            this.dataLock = true;
          } else if (res.data.list.length == 0) {
            this.list1 = [];
            this.textwarn = "到底了";
            this.dataLock = true;
          }
          console.log(this.list1, "eee");
        } catch (error) {
          console.log(error, "postVersatileRegionProductSearch");
        } finally {
          this.loading = false;
          this.showNext = false;
        }
        console.log(this.showNext, this.dataLock, this.textwarn, "text====");
      }
    },
    // handleChange(value) {
    //   console.log(value);
    //   this.formData.first_cid = value[0];
    //   this.formData.cid = value[1];
    //   //   this.formData.buyer_id = "";
    //   this.formData.category_tag_id = "";
    //   //   this.getproductbuyerlist({
    //   //     sku_code: this.formData.sku_code,
    //   //     cid: this.formData.cid,
    //   //   });
    //   this.options.forEach((el) => {
    //     if (el.id == this.formData.first_cid) {
    //       el.children.forEach((item) => {
    //         if (item.id == this.formData.cid) {
    //           this.category_tag_list = [
    //             // { id: 0, name: "无" },
    //             ...item.tags,
    //           ];
    //         }
    //       });
    //     }
    //   });
    // },
    promotion_tag_id_change(e, item) {
      // console.log(e,item,'22222222222222222')
      if (item.id != 0) {
        //之前选择无然后再选择其他的时候去除无的选项
        this.promotion_tag_id = this.promotion_tag_id.filter((item) => {
          return String(item) != "0";
        });
      }
      if (this.promotion_tag_id.includes("0")) {
        //包含无就直接有一个无没有其他的无就直接清空
        this.promotion_tag_id = ["0"];
      }
    },
    async onInitData(e) {
      console.log(e, "e==");
      // this.getcategorylist(e.sku_code);
      this.getinfo({ sku_code: e.sku_code });
      this.postAjaxProductAreaList(e.first_cid);
      this.postAjaxProductUnitList();
    },
    gettaglist(e) {
      this.$api.general.taglist({ logistic_business_id: e }).then((res) => {
        console.log(res, "标签接口");
        this.promotion_tag_list = [{ id: 0, name: "无" }, ...res.data];
      });
    },
    // getcategorylist(e) {
    //   this.$api.general.categoriesList({ sku_code: e }).then((res) => {
    //     console.log(res, "商品分类");
    //     this.options = res.data;
    //     this.getinfo({ sku_code: e });
    //   });
    // },
    //获取详情
    getinfo(e) {
      this.$api.general.productinfo({ sku_code: e.sku_code }).then((res) => {
        console.log(res, "res");
        this.formData = res.data;
        this.formDatacopy = JSON.parse(JSON.stringify(res.data));

        //标签改为多选
        this.promotion_tag_id =
          res.data.promotion_tag_id == 0
            ? ["0"]
            : res.data.promotion_tag_id.split(",");
        //标签改为多选
        if (res.data.creator_type == 1 || res.data.creator_type == 2) {
          this.promotion_tag_id = ["0"];
        }
        this.gettaglist(res.data.logistic_business_id);

        this.formData.cateName =
          res.data.category_tag && res.data.category_tag.name
            ? `${res.data.first_cname}-${res.data.cname}-${res.data.category_tag.name}`
            : ""; // 品类名字回显

        this.cid_type = res.data.cid_type;

        //判断有没有能回显的一级品类
        // if (res.data.first_cid == 0 || res.data.cid == 0) {
        //   this.value = [];
        //   // this.getproductbuyerlist({
        //   //   sku_code: res.data.sku_code,
        //   // });
        // } else {
        //   this.value = [res.data.first_cid, res.data.cid];
        //   // this.getproductbuyerlist({
        //   //   sku_code: res.data.sku_code,
        //   //   cid: res.data.cid,
        //   // });
        // }
        this.getproductbuyerlist({
          sku_code: res.data.sku_code,
        });
        //根据一级品类选择三级品种品牌
        // if (this.value.length > 0) {
        //   let constitem = 0;
        //   let constitem2 = 0;
        //   this.options.forEach((el) => {
        //     //判断如果没有这个一级品类了要清空
        //     if (el.id == this.value[0]) {
        //       constitem = constitem + 1;
        //     }

        //     if (el.id == res.data.first_cid) {
        //       el.children.forEach((item) => {
        //         //判断如果没有这个二级品类了要清空
        //         if (item.id == this.value[1]) {
        //           constitem2 = constitem2 + 1;
        //         }

        //         if (item.id == res.data.cid) {
        //           this.category_tag_list = [
        //             // { id: 0, name: "无" },
        //             ...item.tags,
        //           ];
        //         }
        //       });
        //     }
        //   });

        //   setTimeout(() => {
        //     if (constitem == 0) {
        //       this.value[0] = "";
        //     }
        //     if (constitem2 == 0) {
        //       this.value[1] = "";
        //     }
        //   }, 100);
        // }
        this.dialogVisible = true;
      });
    },
    getproductbuyerlist(e) {
      this.$api.general.productbuyerlist(e).then((res) => {
        console.log(res, "业务员");
        this.buyer_list = res.data;
        let constitem = 0;
        res.data.forEach((item) => {
          if (item.id == this.formData.buyer_id) {
            constitem = constitem + 1;
          }
        });
        setTimeout(() => {
          if (constitem == 0) {
            this.formData.buyer_id = "";
          }
        }, 100);
      });
    },
    onHandleClose() {
      this.dialogVisible = false;
    },
    contrast() {
      let name = "";
      this.datatips.forEach((item) => {
        if (this.formData[item.field] != this.formDatacopy[item.field]) {
          name = name + item.name + ",";
        }
      });
      let newStr = name.substring(0, name.length - 1);
      this.$confirm(
        !name ? "你没有修改字段" : `你修改了${newStr}字段, 是否保存?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          //如果是下架的标签永远传0
          if (this.type == 2) {
            this.formData.promotion_tag_id = "0";
          }
          this.$api.general
            .productedit(this.formData)
            .then((res) => {
              this.dialogVisible = false;
              this.loadingSubmit = false;
              this.$emit("tablechange", true);
            })
            .catch((err) => {
              this.loadingSubmit = false;
            });
        })
        .catch(() => {
          this.loadingSubmit = false;
        });
    },
    onHandleSubmit() {
      this.loadingSubmit = true;
      if (this.promotion_tag_id.includes(0)) {
        //包含无就直接有一个无没有其他的无就直接清空
        this.formData.promotion_tag_id = "0";
      } else {
        this.formData.promotion_tag_id = this.promotion_tag_id.join(",");
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.contrast();
        } else {
          this.loadingSubmit = false;
        }
      });
    },
  },
  created() {},

  watch: {
    /**
     * 图片发生改变
     */
    category_tag_list() {
      let constitem = 0;
      this.category_tag_list.forEach((item) => {
        if (item.id == this.formData.category_tag_id) {
          constitem = constitem + 1;
        }
      });
      setTimeout(() => {
        if (constitem == 0) {
          this.formData.category_tag_id = "";
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.infodata {
  display: flex;
  align-items: center;
  gap: 40px;
  font-size: 16px;
  font-weight: 600;
  padding-left: 70px;
  margin-bottom: 20px;
  box-sizing: border-box;
}
</style>
