<template>
  <el-dialog
    title="商品变更记录"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents" v-loading="dialogLoading">
      <div class="content">
        <!-- 列表 start -->
        <table-list
          :tableData="tableData"
          :details="goodsDetail"
          @on-details="onHandelDetails"
        ></table-list>
        <!-- 列表 end -->

        <!-- 分页 start -->
        <div class="pagination">
          <el-pagination
            type="primary"
            :current-page="formData.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="formData.pageSize"
            :total="total"
            @size-change="onHandleSizeChange"
            @current-change="onHandleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            background
          >
          </el-pagination>
        </div>
        <!-- 分页 end -->
      </div>

      <!-- 右侧详情界面 start -->
      <!-- <div class="detail" v-if="detailsVisible">
        <goods-details
          :loading="detailsLoading"
          :details="goodsDetail"
        ></goods-details>
      </div> -->
      <!-- 右侧详情界面 end -->
      <GoodsDetails ref="GoodsDetailsref"></GoodsDetails>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">返 回</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import TableList from "./modules/table-list.vue";
// import GoodsDetails from "./modules/goods-details.vue";
import GoodsDetails from "@/components/GlobalDetails.vue";
import { getProductChangeRecord } from "@/api/general/goods.js";
// import { mockData } from "./mock";

export default {
  name: "GoodsChangeDialog",
  props: {},
  components: {
    TableList,
    GoodsDetails,
  },
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      detailsVisible: false,
      detailsLoading: false,
      formData: {
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
      skuCode: "", // 商品编号
      goodsDetail: {}, // 商品详情
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;

      this.formDataClone = cloneDeep(this.formData);
      data && this.onInitEditForm(data);
      this.$refs.refForm?.clearValidate();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.skuCode = "";
      this.goodsDetail = {};
      this.total = 0;
      this.tableData = [];
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
      this.detailsVisible = false;
    },
    /**
     * 初始化页面
     */
    onInitEditForm(data) {
      this.skuCode = data.skuCode;
      console.log("🆒 onInitEditForm", data);
      this.ajaxGetTableData();
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.dialogLoading = true;
      const params = { sku_code: this.skuCode, ...this.formData };
      try {
        const { data } = await getProductChangeRecord(params);
        const { total, data: list } = data;
        this.total = total;
        this.tableData = list;
      } catch (err) {
        console.log("ajax getProductChangeRecord err", err);
      } finally {
        this.dialogLoading = false;
        // 模拟数据
        // this.total = mockData.total;
        // this.tableData = mockData.tableData;
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
    /**
     * 展示详情
     */
    onHandelDetails(row) {
      // this.detailsVisible = true;
      // this.detailsLoading = true;
      this.goodsDetail = row;
      this.goodsDetail.typenew = 2;
      this.$refs.GoodsDetailsref.hqlist(this.goodsDetail)
      // // 模拟loading
      // setTimeout(() => {
      //   this.detailsLoading = false;
      // }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    width: 100%;
    padding-top: 30px;
    display: flex;
    .content {
      flex: 1;
      min-width: 600px;
      height: 500px;
      display: flex;
      flex-direction: column;
      .pagination {
        padding: 10px 0;
      }
    }
    .detail {
      width: 375px;
      height: 520px;
      padding-left: 45px;
    }
  }
}
</style>
